@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "oldschoolGrotesk";
  src: url("../src/assets/fonts/oldschool-grotesk/OldschoolGrotesk-NormalBold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "oldschoolGrotesk";
  src: url("../src/assets/fonts/oldschool-grotesk/OldschoolGrotesk-NormalMedium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "oldschoolGrotesk";
  src: url("../src/assets/fonts/oldschool-grotesk/OldschoolGrotesk-NormalRegular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}


html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'oldschoolGrotesk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #FFFFF3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-wrapper {
  transition-timing-function: linear;
}

.swiper-slide {
  display: flex !important;
}

h1{
  font-weight: bold; 
}

h2 {
  font-weight: 500;
}

button .arrows{
  transition: all 0.2s ease;
}

button:hover .arrows {
  margin-left: 5px;
}

button:hover .arrows  .arrow-up {
  visibility: hidden;
  height: 0;
  width: 0;
}

button:hover .arrows  .arrow-right {
  visibility: visible;
  height: 0.5rem;
  width: 0.5rem;
}

button > p {
  text-wrap:nowrap;
}

@media screen and (min-width: 1024px) {

  .store img{
    transition: all 0.3s ease;
    }
    
    .store:hover img {
      height: 100%;
    
    }  
}


@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
} 
.animate-bounce {
  animation: bounce 1.5s infinite alternate;
}